import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioVendasVendedor } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Tabela from '../../components/Tabela/Tabela';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';

function RelVendasVendedor() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relVendasVendedor, setRelVendasVendedor] = useState([]);

  async function GetRelVendasVendedor(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/VendasVendedorReport/GetList', payload);
    let data = res.data;
    if (res.status === 200) {
      setRelVendasVendedor(data.VendasVendedor);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelVendasVendedor(getFiltroPadraoRelatorioVendasVendedor());
    }
  }, [search]);

  useEffect(() => {
    GetRelVendasVendedor(getFiltroPadraoRelatorioVendasVendedor());
  }, []);

  function handleEdit(item) {
    navigate(`/Pedidos/Editar/${item.PedidoId}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relVendasVendedor, null, 2),
    };
    baixarArquivoBase64('/ValidadePedidosReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relVendasVendedor, null, 2),
    };
    baixarArquivoBase64('/ValidadePedidosReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioVendasVendedor()}
        onSubmit={async values => {
          await GetRelVendasVendedor(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por pedidos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <Tabela
          titulo={'RELATÓRIO DE VENDAS POR VENDEDOR'}
          setFunction={setRelVendasVendedor}
          data={relVendasVendedor}
          columns={[
            {
              placeholder: 'PEDIDO',
              name: 'CodigoPedido',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Cliente',
              type: 'text',
            },
            {
              placeholder: 'DATA',
              name: 'Data',
              type: 'date',
            },
            {
              placeholder: 'VALOR',
              name: 'ValorTotal',
              type: 'money',
            },
            {
              placeholder: 'VENDEDOR',
              name: 'Vendedor',
              type: 'text',
            },
            {
              placeholder: 'VALOR DE COMISSÃO',
              name: 'ValorComissao',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="CÓDIGO"
        id="Codigo"
        name="Codigo"
        component={InputNumInt}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="CLIENTE/FORNECEDOR"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="VENDEDOR"
        id="Vendedor"
        name="Vendedor"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Vendedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelVendasVendedor;
