import axios from 'axios';
import { removerDataNull, removerNullJSON } from '../utils/utils';
const api = axios.create({
  baseURL: 'https://api.erpsuite.com.br/api/',
  //baseURL: 'http://localhost:53376/api/',
});

export default api;

api.interceptors.request.use(
  function (config) {
    if (config && config.data && config.data.data) {
      try {
        config.data.data = config.data.data.replaceAll('"Id": ""', '"Id": null');
      } catch (error) { }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    console.log(response)
    //response.data = response.data.replaceAll('0001-01-01', '');
    removerNullJSON(response.data);
    removerDataNull(response.data);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
