import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import {
  getFiltroPadraoRelatorioCentroCustos,
} from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { baixarArquivoBase64 } from '../../utils/download';
import Filtros from '../../components/Filtros/Filtros';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Tabela from '../../components/Tabela/Tabela';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputData from '../../components/Inputs/InputData/InputData';
import { isNotNullOrEmpty } from '../../utils/utils';
import { Box, Table, TableContainer, Td, Th, Thead, Tr } from '@chakra-ui/react';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';

function RelCentroCustos() {
  const [relCentroCustos, setRelCentroCustos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  async function GetRelCentroCustos(filtros) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/CentroCustosReport/GetListV2', payload);
    let data = res.data;
    console.log(data)
    if (res.status === 200) {
      setRelCentroCustos(data.Centros);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelCentroCustos(getFiltroPadraoRelatorioCentroCustos());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelCentroCustos(getFiltroPadraoRelatorioCentroCustos());
    }
  }, [search]);


  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relCentroCustos, null, 2),
    };
    baixarArquivoBase64('/CentroCustosReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(RelCentroCustos, null, 2),
    };
    baixarArquivoBase64('/CentroCustosReport/GetExcel', payload);
  };


  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioCentroCustos()}
        onSubmit={async values => {
          await GetRelCentroCustos(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            }
          ]}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
          <InputFiltro
            titulo="EMPRESA"
            id="Empresa"
            name="Empresa"
            component={InputAutoComplete}
            autocomplete={true}
            path={'Empresa'}
            database={localStorage.getItem('database')}
            gridColumn={gridColumn.gc4}
          />
          <InputFiltro
            titulo="Ano"
            id="Ano"
            name="Ano"
            component={InputNumInt}
            gridColumn={gridColumn.gc4}
          />
        </Filtros>
        <Box mb={7} gridColumn={gridColumn.gc12} className="card">
          <SecaoCadastro titulo={'Relatório de Centro de Custos'} />
          <TabelaCentroCustos ano={2025} centros={relCentroCustos} />
        </Box>

      </FormikPadrao>
    </Pagina>
  );
}

const TabelaCentroCustos = ({ ano, centros }) => {
  const colunasMeses = () => {
    const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro",
    ];

    return months.map((month, index) => (
      <Th key={index} colSpan="4">{`${month} ${ano}`}</Th>
    ));
  };

  const renderReceitaDespesaHeaders = () => {
    return Array.from({ length: 12 }).map((_, index) => (
      <React.Fragment key={index}>
        <Th colSpan="2">Receitas</Th>
        <Th colSpan="2">Despesas</Th>
      </React.Fragment>
    ));
  };

  const renderPrevistoRealizadoHeaders = () => {
    return Array.from({ length: 12 }).map((_, index) => (
      <React.Fragment key={index}>
        <Th>Previsto</Th>
        <Th>Realizado</Th>
        <Th>Previsto</Th>
        <Th>Realizado</Th>
      </React.Fragment>
    ));
  };

  const renderRows = () => {
    return centros.map((item, rowIndex) => (
      <Tr key={rowIndex}>
        <Td className="font-weight-bold">{item.Nome.toUpperCase()}</Td>
        {item.ReceitasPrevistas.map((_, colIndex) => (
          <React.Fragment key={colIndex}>
            <Td>R$ {item.ReceitasPrevistas[colIndex].toFixed(2)}</Td>
            <Td>R$ {item.ReceitasRealizadas[colIndex].toFixed(2)}</Td>
            <Td>R$ {item.DespesasPrevistas[colIndex].toFixed(2)}</Td>
            <Td>R$ {item.DespesasRealizadas[colIndex].toFixed(2)}</Td>
          </React.Fragment>
        ))}
      </Tr>
    ));
  };

  return (
    <TableContainer maxWidth={'100%'} whiteSpace={'revert'}>
      <Table className="table table-bordered align-items-center">
        <Thead>
          <Tr className="mes">
            <th colSpan="1"></th>
            {colunasMeses()}
          </Tr>
          <Tr className="receitaDespesa">
            <th colSpan="1"></th>
            {renderReceitaDespesaHeaders()}
          </Tr>
          <Tr className="previstoRealizado">
            <th>Centro de Custos</th>
            {renderPrevistoRealizadoHeaders()}
          </Tr>
        </Thead>
        <tbody className="list">{renderRows()}</tbody>
      </Table>
    </TableContainer>
  );
};

export default RelCentroCustos;
