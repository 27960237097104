import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { getFiltroPadraoRelatorioSinteseFinanceira } from '../../utils/filtros';
import { formataDinheiro, formataFloat } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import {
  ajustaDatas,
  optionsDatas,
} from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';

function RelSinteseFinanceira() {
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);
  const [relSinteseFinanceira, setRelSinteseFinanceira] = useState({
    TotalDespesas: 0,
    TotalReceitas: 0,
    PlanosReceita: [],
    PlanosDespesa: [],
  });


  async function GetRelSinteseFinanceira(filtros) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/SinteseFinanceiraReport/GetListV2', payload);
    let data = res.data;
    setRelSinteseFinanceira(data.Report);
    setLoading(false);
  }


  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelSinteseFinanceira(getFiltroPadraoRelatorioSinteseFinanceira());
    }
  }, [search]);

  useEffect(() => {
    GetRelSinteseFinanceira(getFiltroPadraoRelatorioSinteseFinanceira());
  }, []);

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relSinteseFinanceira, null, 2),
    };
    baixarArquivoBase64('/SinteseFinanceiraReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relSinteseFinanceira, null, 2),
    };
    baixarArquivoBase64('/SinteseFinanceiraReport/GetExcel', payload);
  };
  return (
    <>
      <Pagina loading={loading}>
        <FormikPadrao
          initialValues={getFiltroPadraoRelatorioSinteseFinanceira()}
          onSubmit={async values => {
            await GetRelSinteseFinanceira(values);
          }}
        >
          <BarraBotoes
            maxWindowSize={1030}
            botoes={[
              {
                children: 'Exportar Planilha',
                onClick: getExcel,
                variant: 'verde',
              },
              {
                children: 'Exportar PDF',
                onClick: getPDF,
                variant: 'rosa',
              },
            ]}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />
          <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
          <TotalizadorRelatorio
            items={[
              {
                Titulo: 'TOTAL DE RECEITAS',
                Valor: relSinteseFinanceira.TotalReceitas,
                type: 'money',
              },
              {
                Titulo: 'TOTAL DE DESPESAS',
                Valor: relSinteseFinanceira.TotalDespesas,
                type: 'money',
              },
            ]}
          />
          <Box mb={7} gridColumn={gridColumn.gc6} className="card">
            <SecaoCadastro titulo={'Receitas'} />
            <TableContainer maxWidth={'100%'} whiteSpace={'revert'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Plano de Contas </Th>
                    <Th>Valor</Th>
                    <Th>Percentual</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {relSinteseFinanceira.PlanosReceita.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.Item1}</Td>
                      <Td>{formataDinheiro(item.Item2)}</Td>
                      <Td>{formataFloat(item.Item3)} %</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box mb={7} gridColumn={gridColumn.gc6} className="card">
            <SecaoCadastro titulo={'Despesas'} />
            <TableContainer maxWidth={'100%'} whiteSpace={'revert'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Plano de Contas </Th>
                    <Th>Valor</Th>
                    <Th>Percentual</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {relSinteseFinanceira.PlanosDespesa.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.Item1}</Td>
                      <Td>{formataDinheiro(item.Item2)}</Td>
                      <Td>{formataFloat(item.Item3)} %</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  const optionsData = [
    { value: 'vencimento', name: 'Filtrar por Data de Vencimento' },
    { value: 'competencia', name: 'Filtrar Por Data de Competência' },
  ];

  const optionsTipo = [
    { value: 'previsto', name: 'Previsto' },
    { value: 'realizado', name: 'Realizado' },
  ];
  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="Tipo"
        name="Tipo"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />

      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsTipo}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelSinteseFinanceira;
