import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import IconeDeletar from '../../components/Icones/IconeDeletar/IconeDeletar';
import IconeDownload from '../../components/Icones/IconeDownload/IconeDownload';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputCheckbox from '../../components/Inputs/InputCheckbox/InputCheckbox';
import InputData from '../../components/Inputs/InputData/InputData';
import InputDinheiro from '../../components/Inputs/InputDinheiro/InputDinheiro';
import InputFloat from '../../components/Inputs/InputFloat/InputFloat';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { formataData, formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './LancamentosFinanceiros.css';
import {
  baixarArquivo,
  getPagamentos,
  optionsCentroCustos,
  optionsFormaPagamento,
  optionsTipo,
  valor_inicial,
} from './utils';
import InputArquivos from '../../components/Inputs/InputArquivo/InputArquivos';

function EditarLancamentosFinanceiros() {
  const navigate = useNavigate();

  const [lancamento, setLancamento] = useState(valor_inicial);
  const [pagamentos, setPagamentos] = useState(valor_inicial);

  const [loading, setLoading] = useState(true);

  let { id } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  useEffect(() => {
    async function GetLancamentos() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditLancamentos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        let pagamentos = await getPagamentos(data.Lancamento.Id);
        setPagamentos(pagamentos);
        setLancamento(data.Lancamento);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetLancamentos();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditLancamentos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={lancamento}
          /* validationSchema={yup.object({
             Nome: yup.string().required('O campo é obrigatório.'),
             Empresa: yup.string().required('O campo é obrigatório.'),
           })}*/
          onSubmit={async values => {
            setLancamento(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} pagamentos={pagamentos} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate, pagamentos }) {
  const { values, setFieldValue, handleChange, handleBlur } = useFormikContext();

  const [lastEdited, setLastEdited] = useState(null); // Track last modified field

  useEffect(() => {
    if (lastEdited === "DescontoPercentual" && values.ValorTotal > 0) {
      const desconto = (values.DescontoPercentual / 100) * values.ValorTotal;
      setFieldValue("Desconto", desconto.toFixed(2), false);
    }
  }, [values.DescontoPercentual, values.ValorTotal, setFieldValue, lastEdited]);

  useEffect(() => {
    if (lastEdited === "Desconto" && values.ValorTotal > 0) {
      const percentual = (values.Desconto / values.ValorTotal) * 100;
      setFieldValue("DescontoPercentual", percentual.toFixed(2), false);
    }
  }, [values.Desconto, values.ValorTotal, setFieldValue, lastEdited]);

  useEffect(() => {
    if (values.ValorTotal !== undefined && values.ValorTotal > 0) {
      const valorRestante = values.ValorTotal - parseFloat(values.Desconto || 0);
      setFieldValue("ValorRestante", valorRestante.toFixed(2), false);
    }
  }, [values.Desconto, values.ValorTotal, setFieldValue]);
  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'amarelo',
            onClick: () => { },
            children: 'Importar em Lote',
            visible: false,
            type: 'button',
          },
          {
            variant: 'vermelho',
            onClick: () => navigate('/Lancamentos/Listar'),
            children: 'Cancelar',
            type: 'button',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            type: 'button',
            children: 'Emitir Boleto',
          },
        ]}
        caminho={[
          { nome: 'Lançamentos', path: '/Lancamentos/Listar' },
          { nome: 'Novo Lançamento' },
        ]}
      />
      <Card titulo="NOVO LANÇAMENTO FINANCEIRO">
        <InputFormik
          titulo="Código"
          id="Codigo"
          name="Codigo"
          component={InputNumInt}
          gridColumn={gridColumn.gc2}
        />
        <InputFormik
          titulo="Cliente / Fornecedor"
          id="Cliente"
          name="Cliente"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc3}
          path="Pessoas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Banco"
          id="Banco"
          name="Banco"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc3}
          path="Bancos"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Plano de Contas"
          id="PlanoConta"
          name="PlanoConta"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="PlanosContas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Tipo"
          id="Tipo"
          name="Tipo"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsTipo}
        />
        <InputFormik
          titulo="Valor (R$)"
          id="Valor"
          name="Valor"
          component={InputDinheiro}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Competência"
          id="DataCompetencia"
          name="DataCompetencia"
          component={InputData}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Vencimento"
          id="DataVencimento"
          name="DataVencimento"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Quitação"
          id="DataPagamento"
          name="DataPagamento"
          component={InputData}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Forma de Pagamento"
          id="FormaPagamento"
          name="FormaPagamento"
          component={InputSelect}
          options={optionsFormaPagamento}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo=" Centro de Custos"
          id="CentrosCustos"
          name="CentrosCustos"
          component={InputSelect}
          options={optionsCentroCustos}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Pago"
          id="Pago"
          name="Pago"
          component={InputToggle}
          disabled={true}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Desconsiderar multa e juros?"
          id="NaoConsiderarMulta"
          name="NaoConsiderarMulta"
          component={InputToggle}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Multa (R$)"
          id="Multa"
          name="Multa"
          component={InputDinheiro}
          gridColumn={gridColumn.gc3}
          disabled={values.NaoConsiderarMulta}
        />
        <InputFormik
          titulo="Juros (R$)"
          id="Juros"
          name="Juros"
          component={InputDinheiro}
          gridColumn={gridColumn.gc3}
          disabled={values.NaoConsiderarMulta}
        />
        <InputFormik
          titulo="Mora Diária (%)"
          id="MoraDiaria"
          name="MoraDiaria"
          component={InputFloat}
          gridColumn={gridColumn.gc2}
        />
        <InputFormik
          titulo="Desconto (R$)"
          id="Desconto"
          name="Desconto"
          component={InputDinheiro}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Desconto (%)"
          id="DescontoPercentual"
          name="DescontoPercentual"
          component={InputFloat}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="A pagar (R$)"
          id="ValorRestante"
          name="ValorRestante"
          component={InputDinheiro}
          gridColumn={gridColumn.gc3}
          disabled
        />
        <InputFormik
          titulo="Total (R$)"
          id="ValorTotal"
          name="ValorTotal"
          component={InputDinheiro}
          gridColumn={gridColumn.gc3}
          disabled
        />
      </Card>
      <Card titulo="OBSERVAÇÕES GERAIS">
        <InputFormik
          titulo="Observações"
          id="Observacoes"
          name="Observacoes"
          component={InputTextArea}
          gridColumn={gridColumn.gc12}
        />
      </Card>
      {values.Id &&
        <Card titulo="ANEXO DE ARQUIVOS">
          <InputArquivos handleUpload={() => { }} botao />
          <Box className="arquivosCadastrados" gridColumn={gridColumn.gc12}>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>NOME</Th>
                    <Th>BAIXAR</Th>
                    <Th>
                      <IconeDeletar />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {values.Arquivos.map((arquivo, index) => (
                    <Tr key={index}>
                      <Td>{arquivo}</Td>
                      <Td>
                        <IconeDownload
                          onClick={() => baixarArquivo(values.Id, arquivo)}
                        />
                      </Td>
                      <Td>
                        <IconeDeletar />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Card>}
      {values.Id &&
        <Card titulo="NOVO PAGAMENTO">
          <Box p={2} gridColumn={gridColumn.gc2}>
            <p>Quitar</p>
            <InputCheckbox
              id="Quitar"
              name="Quitar"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Quitar}
            />
          </Box>
          <Box p={2} gridColumn={gridColumn.gc3}>
            <p>Valor</p>
            <InputDinheiro
              id="Valor"
              name="Valor"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Valor}
            />
          </Box>
          <Box p={2} gridColumn={gridColumn.gc3}>
            <p>Forma de Pagamento</p>
            <InputSelect
              id="FormaPagamento"
              name="FormaPagamento"
              options={optionsFormaPagamento}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.FormaPagamento}
            />
          </Box>
          <Box p={2} gridColumn={gridColumn.gc3}>
            <p>Data de Pagamento</p>
            <InputData
              id="DataPagamento"
              name="DataPagamento"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.DataPagamento}
            />
          </Box>
          <Box className="arquivosCadastrados" gridColumn={gridColumn.gc12}>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>VALOR</Th>
                    <Th>FORMA DE PAGAMENTO</Th>
                    <Th>DATA DE PAGAMENTO</Th>
                    <Th>RECIBO</Th>
                    <Th>
                      <IconeDeletar />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pagamentos &&
                    pagamentos.map(pagamento => (
                      <Tr className="arquivo" key={pagamentos.indexOf(pagamento)}>
                        <Td>{formataDinheiro(pagamento.Valor)}</Td>
                        <Td>{pagamento.FormaPagamento}</Td>
                        <Td>{formataData(pagamento.DataPagamento)}</Td>
                        <Td>{'BAIXAR RECIBO ÍCONE'}</Td>
                        <Td>
                          <IconeDeletar />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Card>}

    </>
  );
}

export default EditarLancamentosFinanceiros;
