function formataData(date) {
    //recebe data da API e formata para o formato dd/mm/yyyy
    var dataFormatada = date.replace(/(\d*)-(\d*)-(\d*).*/, '$3-$2-$1');
    return dataFormatada;
}


function formataDinheiro(valor) {
    if (!valor) {
        valor = 0;
    }
    //recebe valor da API e formata para o formato R$ 0,00
    let valorFormatado = valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    return valorFormatado;
}

function formataFloat(valor, casasDecimais = 2) {
    //recebe valor da API e formata para o formato 0,00
    let valorFormatado = valor.toLocaleString('pt-br', { minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais });
    return valorFormatado;
}

export { formataData, formataDinheiro, formataFloat };
